import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

export const query = graphql`
  query getNewsPost($slug: String!) {
    sanityNews(slug: { current: { eq: $slug } }) {
      title
      tagline
      _rawCopy
      _createdAt(formatString: "dddd MMMM Do, YYYY hh:mma")
      attachments {
        asset {
          url
          mimeType
          originalFilename
        }
      }

      image {
        asset {
          url
          localFile {
            childImageSharp {
              fluid(grayscale: true, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

let Container = tw.section`
  container -mt-32 flex flex-col lg:flex-row pb-8
`

let H1 = tw.h1`
   text-5xl text-primary font-sans font-bold leading-none pb-4
`

let Image = styled(Img)`
  ${tw` w-full z-10 relative`};
  display: block;
  max-height: 400px;
`

let ContentArea = tw.div`w-full lg:w-2/3 text-primary bg-white
lg:py-12 lg:px-8 z-30 bg-white relative mr-4 order-2 lg:order-1 lg:shadow-xl
`

let MetaArea = tw.div`
    bg-secondary text-white w-full lg:w-1/3 z-30 self-start mt-16 mb-8 p-8 order-1 lg:order-2 font-sans
`

let MetaHeading = tw.p`
  text-xl mb-2
`

let MetaBody = tw.p`
  italic
`

let P = tw.p`
    text-gray-800 mb-4
`

let Content = styled(BlockContent)`
  ${tw`text-primary font-sans mt-8`};
`

const serializer = {
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <P>{props.children}</P>
      }
    },
  },
}

const BackButton = tw.p`
text-primary cursor-pointer pb-4
`

let FileIcons = new Map([["application/pdf", faFilePdf]])

let Icon = styled(FontAwesomeIcon)`
  ${tw`mx-2`}
`

let AttachmentLink = tw.a`flex gap-4 items-center text-blue-800 text-lg mt-2`

let AttachmentsTitle = tw.h3`
  font-sans text-xl mt-4 text-blue-800
`

const NewsPost = props => {
  const {
    sanityNews: { title, tagline, image, _createdAt, _rawCopy, attachments },
  } = props.data

  return (
    <Layout>
      <SEO title={title} image={image.asset.url} description={tagline} />
      <Image fluid={{ ...image.asset.localFile.childImageSharp.fluid }} />
      <Container>
        <ContentArea>
          <BackButton
            onClick={() => {
              window.history.go(-1)
            }}
          >
            <Icon icon={faArrowLeft} />
            Go Back
          </BackButton>
          <H1>{title}</H1>
          <MetaBody>{tagline}</MetaBody>
          <Content
            blocks={_rawCopy}
            serializers={serializer}
            projectId={process.env.GATSBY_SANITY_ID}
            dataset={process.env.GATSBY_SANITY_DATASET}
          />
          {attachments.length ? (
            <AttachmentsTitle>Attachments</AttachmentsTitle>
          ) : null}
          {attachments.map(({ asset: a }) => (
            <AttachmentLink href={a.url}>
              <FontAwesomeIcon
                icon={FileIcons.get(a.mimeType)}
                size="xl"
                color={""}
              />
              <p>{a.originalFilename}</p>
            </AttachmentLink>
          ))}
        </ContentArea>
        <MetaArea>
          <MetaHeading>Published on:</MetaHeading>
          <MetaBody>{_createdAt}</MetaBody>
        </MetaArea>
      </Container>
    </Layout>
  )
}

export default NewsPost
